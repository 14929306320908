require("chart.js");

let dataset = [{
    label: 'Bulldog',
    data: [100, 50, 300, 200, 250, 130, 200],
    backgroundColor: 'rgba(23, 172, 255, 0.6)',
    borderColor: 'rgba(23, 172, 255, 0.6)',
    borderWidth: 1
},{
    label: 'Zero',
    data: [300, 10, 200, 400, 170, 150, 230],
    backgroundColor: 'rgba(223, 67, 67, 0.6)',
    borderColor: 'rgba(223, 67, 67, 0.6)',
    borderWidth: 1
},{
    label: 'Capital',
    data: [200, 50, 220, 255, 115, 130, 200],
    backgroundColor: 'rgba(223, 189, 67, 0.6)',
    borderColor: 'rgba(223, 189, 67, 0.6)',
    borderWidth: 1
},{
    label: 'BIP',
    data: [400, 310, 30, 200, 280, 130, 250],
    backgroundColor: 'rgba(154, 223, 67, 0.6)',
    borderColor: 'rgba(154, 223, 67, 0.6)',
    borderWidth: 1
}];

let item = document.querySelector('.graph__itself canvas');
if(item !== null) {
    let ctx = item.getContext('2d');
    ctx.height = 300;
    let chart = new Chart(ctx, {
        type: 'line',
        data: {
            labels: ['1', '2', '3', '4', '5', '6', '7'],
            datasets: dataset,
        },
        options: {
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        }
    });
}