$(function() {
    $(".dropdown__title").on("click", function() {
        $actor = $(this);
        $dropdown = $actor.parents(".dropdown");
        $dropdown.toggleClass("active");
        $dropdown.find(".dropdown__list").toggleClass("dropdown__list--active");

        if($dropdown.hasClass("active")) {
            $actor.css({borderRadius: "20px 20px 0 0"});
        } else {
            $actor.removeAttr("style");
        }
    });

    $(".dropdown__list-item").on("click", function() {
        if($(this).parents(".dropdown").hasClass("dropdown--radio")) {
            console.log('radio');
            $(this)
                .addClass("dropdown__list-item--active")
                .siblings()
                .removeClass("dropdown__list-item--active");
        } else {
            $(this).toggleClass("dropdown__list-item--active");
            console.log('not radio');
        }
    });
});