import jquery from "jquery";
window.$ = window.jQuery = jquery;

require("./faq.js");
require("./dropdown.js");
require("./tabs.js");
require("./charts.js");
require("./charts.js");
require("./popup.js");
require("./validation.js");
require("./mask.js");
require("./scrollto.js");
require("./navbar.js");