require("jquery-validation");

$(function() {
    $(".form").each(function() {
        $(this).validate({
            rules: {
                name: "required",
                phone: "required",
                email: {
                    required: true,
                    email: true
                },
                comment: "required"
            },
            messages: {
                name: "Введите Ваше имя",
                phone: "Введите Ваш номер телефона",
                email: {
                    required: "Введите Вашу почту",
                    email: "Введите корректную почту"
                },
                comment: "Введите Ваше сообщение"
            }
        });
    });
});