$(function() {
    $(".navbar__burger").on("click", function() {
        $(this).toggleClass("active");

        if($(this).hasClass("active")) {
            $(".navbar__list").fadeOut(300);
        } else {
            $(".navbar__list").fadeIn(300).css({display: "flex"});
        }
    });
});