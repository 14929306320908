$(function() {
    $prevTab = $(".tabs__list-item--active").prev()

    $(".tabs__list-item").removeAttr("style");
    if($prevTab.is(":first-of-type")) {
        $prevTab.css({borderRadius: "20px"});
    } else {
        $prevTab.css({borderRadius: "0 20px 20px 0"});
    }

    function switchTab(that) {
        // Switch tab in menu
        $(that)
            .addClass("tabs__list-item--active")
            .siblings()
            .removeClass("tabs__list-item--active");

        $prevTab = $(".tabs__list-item--active").prev().find("span");

        $(".tabs__list-item span").removeAttr("style");
        $prevTab.css({borderRadius: "0 0 20px 0"});

        // Switch tab
        const index = $(that).index();
        $(".tabs__blocks-item")
            .eq(index)
            .fadeIn(200)
            .siblings()
            .hide();
    }
	
    $(".tabs__list-item").on("click", function() {
        switchTab(this);
    });
});