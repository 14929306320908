var scroll = 0,
	saveScroll,
	currentPopUp = -1;

function showPopUp(popUpId) {
	if(currentPopUp != -1) {
		hidePopUp(currentPopUp);
	}
	currentPopUp = popUpId;
	saveScroll = scroll;

	var $popUp = $(".pop-up[data-pop-up='" + popUpId + "']");

	$("body").addClass("body-fixed");
	$popUp.fadeIn(300).css("display", "flex");

	if(popUpId == "success") {
		setTimeout(function() {
			hidePopUp(popUpId)
		}, 4000);
	}
}

function hidePopUp(popUpId) {
	if(currentPopUp === -1) return;
	currentPopUp = -1;
	$("body").removeClass("body-fixed");
	$("body").scrollTo(saveScroll, 300);
	
	$(".pop-up[data-pop-up='" + popUpId + "']").fadeOut(300);
}

$(window).scroll(function () {
    scroll = $(window).scrollTop();
});

$(function() {
	$("body").on("click", ".pop-up__opener", function() {
		var popup = $(this).data("pop-up");

		if(popup == "dynamic") {
			$opener = $(this);
			$popup = $(".pop-up[data-pop-up='" + popup + "']");

			$popup.find(".pop-up__title").html($opener.data("title"));
			$popup.find(".pop-up__desc").html($opener.data("desc"));
			$popup.find("button").html($opener.data("button"));
			$popup.find("input[name='dyntitle']").val($opener.data("title"));
		}

		showPopUp($(this).data("pop-up"));
	});

	$(".pop-up__close, .pop-up__bg").on("click", function(e) {
		hidePopUp(currentPopUp);
	});
	
});